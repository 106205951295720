<template>
  <div v-if="data && data.id" :class="'question_view' + (data.is_resolved ? ' is_resolved ' : '')">

    <div class="avatar_col">
      <NuxtLink class="avatar" :to="data.item_user.link">
        <img loading="lazy" :src="data.item_user.avatar_small" :alt="data.item_user.name">
        <div :class="'user_range u_range_' + data.item_user.status"></div>
        <div class="round"></div>
      </NuxtLink>
    </div>
    <div class="content_col">
      <div class="top">
        <NuxtLink class="avatar" :to="data.item_user.link">
          {{ data.item_user.name }}        
        </NuxtLink>        
        <span class="date">{{ $t('week_view_question_started_grow_question') }} {{ $dayjs(data.add_date).utc().fromNow() }}</span>
      </div>

      
      <div 
        class="body" 
        v-html="formatCommonText(data.text ? data.text : data.title)" 
        ref="refProblemText"
        ></div>


      <div class="gallery">

      <a 
        v-for="(photo, photo_key) in data.items_problem_photo"
        :key="photo_key"
        :href="photo.size_big"
        data-fancybox="gallery"
        :data-gallery-id="photo.id" 
        :data-pswp-width="2400"
        :data-pswp-height="3200"
        @click.stop.prevent="openGallery(photo_key)"
        >
        <img loading="lazy" :src="photo.size_m" :alt="data.item_user.name">
      </a>

      <GeneralLightbox 
        v-if="lightboxShow"
        :index="lightboxIndex" 
        :photos="lightboxPhotos" 
        @close="lightboxShow = false" 
        />



      </div>


      <div class="bottom">
        <div class="type">
          <div class="symptoms">

            <div class="item state">              
              <i v-if="!data.is_resolved" class="icon-warning-triangle"></i> 
              <i v-if="data.is_resolved" class="icon-success-round"></i>         
              {{ data.is_resolved ? $t('grow_questions_view_category_solved') : $t('grow_questions_view_category_opened') }}              
            </div>


            <NuxtLink 
              v-if="data.item_week && data.item_week.days"
              :to="data.item_week.link" 
              class="item"
              >
              {{ $t('grow_questions_view_week') }} 
              {{ data.item_week.days }}
            </NuxtLink>
            
                  
            <div 
              v-for="(symptom, symptom_key) in data.items_problem_symptom"
              :key="symptom_key"
              class="item">
              {{ $t('universal_plant_symptoms_type_' + symptom.type) }}. 
              {{ $t('universal_plant_symptom_' + symptom.id) }}        
            </div>

          </div>
        </div>



        <div class="status">

          <template v-if="useAuth().isOwner(data.item_user.id)">
            
            <div class="reply">
              <i class="icon-leaf"></i> 
              {{ data.cnt_likes ? data.cnt_likes : '' }} 
              {{ $plurals.get('like', data.cnt_likes) }}     
            </div>


            <div :class="{'reply': true, 'gloading': removeLoading}" @click="remove(data.id)">
              <i class="icon-remove"></i>
              {{ $t('week_view_question_remove_question') }}           
            </div>
              
          </template>
          <template v-else>

            <div :class="{'reply': true, 'active': props.data?.is_liked, 'gloading': likeLoading}" 
              @click="like(data.id)">
              <i class="icon-leaf"></i> 
              {{ data.cnt_likes ? data.cnt_likes : '' }}
              {{ $plurals.get('like', data.cnt_likes) }}     
            </div>
            
            <div class="reply" v-if="useAuth().isAuth() && !useAuth().isOwner(data.item_user.id)" @click="complain(data.id)">
              <i class="icon-warning-round"></i>
              {{ $t('week_view_question_complain') }}           
            </div>
            

            <GeneralTranslateButton
              v-if="data.text && refProblemText"
                :el="refProblemText"
                :text="data.text"
                :id="data.id"
                :type="'problem'"
                :locale="$i18n.locale"
                />
             
            <div class="reply" v-if="!props.data.is_resolved" @click="reply(data.id)">
              <i class="icon-plus"></i>
              {{ $t('week_view_question_add_solution') }}           
            </div>
                      
          </template>
         
          
        </div>
      </div>


    </div>
  </div>

</template>

<script setup lang="ts">

import { formatCommonText } from '@/types/other';
 
const { t } = useI18n();
const { $api, $likes, $popup, $complains } = useNuxtApp()
const emit = defineEmits(['reply']);

const router = useRouter();

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
})

const likeLoading = ref(false);
const removeLoading = ref(false);
const lightboxShow = ref(null);
const refProblemText = ref(null);
const lightboxIndex = ref(0);
const lightboxPhotos = computed(() => {
  return props.data.items_problem_photo.map((item, key) => {
    return {
      base: item.size_big,
      zoom: item.size_big, 
    }
  })
})

const complain = function(id){


  if(!useAuth().isAuth())
    return useAuth().goToLogin();
  

  if(!confirm(t('chat_complain_confirm_text')))
    return;

  $complains.add('problem', id)
  .then((res) => {                 
    $popup.success('success');
  })
  .catch(function (error) { 
          
  });  
}
 
const reply = function(id){

  if(!useAuth().isAuth())
    return useAuth().goToLogin();
  
  emit('reply', id);
}
 

const remove = function(id){
  
  if(!useAuth().isAuth())
    return useAuth().goToLogin();

  removeLoading.value = true;

  $api.deleteQuestion(id)
  .then((res) => {          
    $popup.success('Deleted');
    removeLoading.value = false;
    router.push('/grow-questions');
    
  })
  .catch(function (error) { 
    $popup.error('Error deleting');
  });  
}

const like = function(id){
  
  if(!useAuth().isAuth())
    return useAuth().goToLogin();

  likeLoading.value = true;
  $likes.toggle('problem', id, props.data.is_liked)
  .then((res) => {          
    
    likeLoading.value = false;
    console.log('liked');       
    console.log(res);            
    
    if(props.data.is_liked){
      props.data.is_liked = 0;
      props.data.cnt_likes--;
    }else{
      props.data.is_liked = 1;
      props.data.cnt_likes++;
    }
  })
  .catch(function (error) { 
    likeLoading.value = false;
    console.log('like error');       
    console.log(error);  
    props.data.cnt_likes--;               
  });  
}



const openGallery = function(key){
  lightboxShow.value = true;
  lightboxIndex.value = key;
}




 
 

</script>

<style scoped>
 
 
 .question_view {
    background-color: var(--gd-question-back-active-color);
    border-radius: 5px;
    padding: 20px;
    display: flex;
    align-items: flex-start;
}
.question_view.is_resolved{
    background-color: var(--gd-question-back-resolved-color);
}
 .question_view .avatar_col{
    width: 70px;
}
 .question_view .avatar_col .avatar{
  position: relative;
  width: 50px;
  display: inline-block;
  height: 50px;
}
.question_view .avatar_col .avatar img{
  border-radius: 100px;
}
 .user_range {
  float: left;
  font-size: 1.3rem;
  margin-right: 15px;
  position: absolute;
  /* margin-left: -28px; */
  bottom: -3px;
  right: -19px;
}
 .question_view .content_col{
    width: calc(100% - 70px);
}
 .question_view .content_col .top{
    margin-bottom: 5px;
}
 .content_col .top .user_name{
    font-weight: bold;
}
 .question_view .content_col .top .date{
    color: var(--gd-question-text-gray-color);
    font-size: 0.875em;
    margin-left: 5px;
}
 .question_view .content_col .body{
    word-wrap: break-word;
    white-space: pre-wrap;
}
 .question_view .content_col > .translate{
    opacity: 0.6;
    margin-top: 5px;
    cursor: pointer;
}
 .question_view .content_col > .translate:hover{
    opacity: 1;
}

 .question_view .content_col .bottom{
    margin-top: 15px;
}
 .question_view .content_col .bottom .type{
    display: inline-block;
}
 .question_view .content_col .bottom .type .icon.warning{
  color: red;
}
 .question_view .content_col .bottom .type .name{
  font-weight: bold;
  margin-right: 4px;
}
 .question_view .content_col .bottom .type .sub{}
 .question_view .content_col .bottom .status{
    width: 100%;
    font-size: 0.875em;
    margin-top: 1rem;
}
 .question_view .content_col .bottom .status > *{
  cursor: pointer;
  display: inline-block;
  margin: 0em 0.75em 0em 0em;
  color: var(--gd-question-text-gray-color);
  position: relative;
}
.question_view .content_col .bottom .status > *.active{
  color: var(--gd-button-small-active-color);
}
 .question_view .gallery{display: flex;flex-wrap: wrap;margin-top: 10px;}

 .question_view .gallery > a{width: 120px;margin-right: 10px;}
 .question_view .gallery > a > img{width: 100%;border-radius: 3px;}
 .question_view .symptoms{
  display: flex;
  margin-top: 0.5rem;
  flex-wrap: wrap;
  width: 100%;
}

 .question_view .symptoms .item{
  background-color: var(--un-background-color-red-light);
  color: var(--un-text-color-red);
  border-radius: 30px;
  padding: 2px 12px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.question_view.is_resolved .symptoms .item{
    background-color: var(--un-background-color-firm-light);
    color: var(--un-text-color-firm);
}
.question_view.is_resolved .question .symptoms .item.state{
    background-color: var(--un-background-color-firm-light);
    color: var(--un-text-color-firm);
}

@container pb (max-width: 768px) {
   .question_view .content_col .bottom .status{
    display: block;
    float: none;
    margin-top: 20px;
  }
}


</style>
